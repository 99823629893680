import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    rootThread: null,
    listMode: false,
    checkboxMode: false,
    checkedItems: { folders: [], files: [] },
    images: [],
    activeImageIndex: 0,
    imageViewerVisible: false,
}

const threadSlice = createSlice({
    name: 'thread',
    initialState,
    reducers: {
        setRootThread: (state, { payload }) => {
            state.rootThread = payload.id
        },
        setListMode: (state, { payload }) => {
            state.listMode = payload
        },
        setCheckboxMode: (state, { payload }) => {
            state.checkboxMode = payload
        },
        checkItem: (state, { payload }) => {
            const type = `${payload.type}s`
            state.checkedItems[type].push(payload.value)
        },
        uncheckItem: (state, { payload }) => {
            const type = `${payload.type}s`
            state.checkedItems[type] = state.checkedItems[type].filter((chid) => chid !== payload.value)
        },
        checkItems: (state, { payload }) => {
            const type = `${payload.type}s`
            state.checkedItems[type] = [...state.checkedItems[type], ...payload.value]
        },
        clearCheckedItems: (state) => {
            state.checkedItems = initialState.checkedItems
        },
        setThreadImages: (state, { payload }) => {
            state.images = payload
        },
        setImageViewerVisible: (state, { payload }) => {
            state.imageViewerVisible = payload
        },
        setImageViewerIndex: (state, { payload }) => {
            state.activeImageIndex = state.images.findIndex(({ id }) => String(id) === String(payload))
        },
    },
})

export const {
    setRootThread,
    setListMode,
    setCheckboxMode,
    checkItem,
    uncheckItem,
    checkItems,
    clearCheckedItems,
    setThreadImages,
    setImageViewerVisible,
    setImageViewerIndex,
} = threadSlice.actions
export default threadSlice.reducer
