import { createSlice } from '@reduxjs/toolkit'
import omit from 'lodash/omit'

const initialState = {
    filters: {},
}

const tableSlice = createSlice({
    name: 'thread',
    initialState,
    reducers: {
        setFilters: (state, { payload }) => {
            const [[key, values]] = Object.entries(payload.filter)

            if (!state.filters[payload.tableKey]) {
                state.filters[payload.tableKey] = {}
            }

            state.filters[payload.tableKey][key] = values
        },
        unsetFilter: (state, { payload }) => {
            state.filters = omit(state.filters, payload)
        },
    },
})

export const { setFilters, unsetFilter } = tableSlice.actions
export default tableSlice.reducer
