import React from 'react'

const getKey = (event) => {
    switch (event) {
        case 'file_uploaded':
            return 'file'
        case 'news_added':
            return 'news'
        default:
            return 'thread'
    }
}

export const notificationDate = (data) => {
    return data[getKey(data.event)].localized_date
}

export const notificationTitle = (data) => {
    const { country = {} } = data[getKey(data.event)]
    return country ? country.display_name : ''
}

export const notificationDescription = (data) => {
    switch (data.event) {
        case 'file_uploaded':
            return (
                <div>
                    Has uploaded <span>{data.file.file_name}</span> to <span>{data.file.model.name}</span>
                </div>
            )
        case 'news_added':
            return (
                <div>
                    Has created a new article <span>{data.news.title}</span>
                </div>
            )
        case 'thread_created':
            return (
                <div>
                    Has created folder <span>{data.thread.name}</span> in{' '}
                    <span>{data.thread.parent ? data.thread.parent.name : ''}</span>
                </div>
            )
        default:
            return <div>No description</div>
    }
}

export const notificationUrl = (data) => {
    switch (data.event) {
        case 'file_uploaded':
            return `/thread/${data.file.model.id}`
        case 'news_added':
            return `/internal-news-article/${data.news.id}`
        default:
            return `/thread/${data.thread.id}`
    }
}
