import React from 'react'
import { Avatar, Space, Dropdown, Menu } from 'antd'
import Icon from '@/components/Icon'
import AlertsBar from '@/components/AlertBar'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Question from '../theme/img/question-mark.svg'

const Container = styled.div`
    display: flex;
    cursor: pointer;
    margin-left: auto;

    .ant-dropdown {
        min-width: 0 !important;
        margin-left: 4px;
    }
`

const Wrapper = styled.div`
    margin-top: 1px;
    margin-right: 24px;
    img {
        transition: transform 0.2s, opacity 0.2s;
        &:hover {
            transform: scale(1.04);
            opacity: 0.88;
        }
    }
`

const FlagIcon = styled.span`
    width: 32px !important;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #efefef;
    vertical-align: middle;
`

const menu = (
    <Menu>
        <Menu.Item>
            <Link to="/logout">Logout</Link>
        </Menu.Item>
    </Menu>
)

const HelperMember = (
    <Menu>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/DPPW/pages/293568513/Awork+User+Guideline+-+Aptos+Employees#Marketing-Manager---File-Storage "
                target="_blank"
                rel="noreferrer"
            >
                How to use File Storage?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/DPPW/pages/293568513/Awork+User+Guideline+-+Aptos+Employees#Adding-new-training-course"
                target="_blank"
                rel="noreferrer"
            >
                How to add courses?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/DPPW/pages/293568513/Awork+User+Guideline+-+Aptos+Employees#Changing-Course-Statuses"
                target="_blank"
                rel="noreferrer"
            >
                How to change course status?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/DPPW/pages/293568513/Awork+User+Guideline+-+Aptos+Employees#Closing-The-Course"
                target="_blank"
                rel="noreferrer"
            >
                How to close the course?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/DPPW/pages/293568513/Awork+User+Guideline+-+Aptos+Employees#Generating-Bonus"
                target="_blank"
                rel="noreferrer"
            >
                How to generate bonus?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/DPPW/pages/293568513/Awork+User+Guideline+-+Aptos+Employees#Generating-Certificate"
                target="_blank"
                rel="noreferrer"
            >
                How to generate certificate?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/DPPW/pages/293568513/Awork+User+Guideline+-+Aptos+Employees"
                target="_blank"
                rel="noreferrer"
            >
                Check full documentation
            </a>
        </Menu.Item>
    </Menu>
)

const HelperCountry = (
    <Menu>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/AD/pages/291667969/Awork+User+Guideline#Marketing-Manager---File-Storage"
                target="_blank"
                rel="noreferrer"
            >
                How to use File Storage?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/AD/pages/291667969/Awork+User+Guideline#Adding-new-training-course"
                target="_blank"
                rel="noreferrer"
            >
                How to add courses?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/AD/pages/291667969/Awork+User+Guideline#Entering-Post-Training-Information"
                target="_blank"
                rel="noreferrer"
            >
                How to enter post-training information?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/AD/pages/291667969/Awork+User+Guideline#Certificate"
                target="_blank"
                rel="noreferrer"
            >
                How to download certificate?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/AD/pages/291667969/Awork+User+Guideline#Doctors"
                target="_blank"
                rel="noreferrer"
            >
                How to check/search registered doctors?
            </a>
        </Menu.Item>
        <Menu.Item>
            <a
                href="https://aptosllc.atlassian.net/wiki/spaces/AD/pages/291667969/Awork+User+Guideline"
                target="_blank"
                rel="noreferrer"
            >
                Check full documentation
            </a>
        </Menu.Item>
    </Menu>
)

const UserMenu = ({ socket, ...props }) => {
    const { name, avatar, flag, role, role_id: rollID, is_manager: isManager } = useSelector((state) => state.user)
    return (
        <Container {...props}>
            {role === 'country' && <AlertsBar socket={socket} />}
            {rollID && isManager ? (
                <div>
                    <Wrapper>
                        <Dropdown overlay={HelperMember} placement="bottomLeft" arrow trigger={['click']}>
                            <img src={Question} alt="" width={24} />
                        </Dropdown>
                    </Wrapper>
                </div>
            ) : (
                <Wrapper>
                    <Dropdown overlay={HelperCountry} placement="bottomLeft" arrow trigger={['click']}>
                        <img src={Question} alt="" width={24} />
                    </Dropdown>
                </Wrapper>
            )}
            {role === 'root' && (
                <div>
                    <Wrapper>
                        <Dropdown overlay={HelperMember} placement="bottomLeft" arrow trigger={['click']}>
                            <img src={Question} alt="" width={24} />
                        </Dropdown>
                    </Wrapper>
                </div>
            )}
            <Dropdown
                arrow
                placement="bottomRight"
                trigger={['click']}
                overlay={menu}
                getPopupContainer={(node) => node}
            >
                <Space>
                    Hello, <b>{name}</b>
                    {flag ? (
                        <FlagIcon className={`${flag} flag-icon-squared`} />
                    ) : (
                        <Avatar src={avatar} icon={<Icon icon={['far', 'user']} />} />
                    )}
                </Space>
            </Dropdown>
        </Container>
    )
}

export default UserMenu
