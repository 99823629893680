import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({ btn = false, style: elementStyle = {}, ...props }) => {
    const buttonIconStyle = !btn ? {} : { marginRight: 4 }
    const style = { ...buttonIconStyle, ...elementStyle }

    if (typeof props.icon === 'string' && props.icon.startsWith('flag-icon')) {
        const { icon, ...flagProps } = props
        return <span className={props.icon} style={style} {...flagProps} />
    }
    return <FontAwesomeIcon {...props} style={style} />
}

export default Icon
