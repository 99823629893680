const pdm = {
    key: 'pdm',
    label: 'PDM',
    icon: 'hospital-user',
    route: { params: { page: 'pdm' }, name: 'dashboard' },
    permissions: ['role', 'pdm'],
    children: [
        {
            key: 'patients',
            label: 'Patients',
            icon: 'bullseye',
            route: { params: { configName: 'patients' }, name: 'listing' },
            permissions: ['view patients', 'pdm-patients'],
            children: [
                {
                    key: 'procedures',
                    label: 'Procedures',
                    icon: 'bullseye',
                    route: { params: { configName: 'procedures' }, name: 'listing' },
                    permissions: ['view procedures', 'pdm-procedures'],
                },
            ],
        },
        {
            key: 'reports',
            label: 'Reports',
            icon: 'fa-chart-column',
            route: { params: { page: 'reports' }, name: 'dashboard' },
            permissions: ['view', 'pdm-reports'],
            children: [
                {
                    key: 'pdm-finance-report',
                    label: 'Finance',
                    icon: 'circle-dollar-to-slot',
                    route: { params: { configName: 'pdm-finance-report' }, name: 'listing' },
                    permissions: ['view finance reports', 'pdm-reports'],
                },
                {
                    key: 'pdm-qa-report',
                    label: 'QA',
                    icon: 'circle-dollar-to-slot',
                    route: { params: { configName: 'pdm-qa-report' }, name: 'listing' },
                    permissions: ['view qa reports', 'pdm-reports'],
                },
                {
                    key: 'pdm-assessment-report',
                    label: 'Assessment report',
                    icon: 'circle-dollar-to-slot',
                    route: { params: { configName: 'pdm-assessment-report' }, name: 'listing' },
                    permissions: ['view assessment reports', 'pdm-reports'],
                },
                {
                    key: 'pdm-adverse-event-report',
                    label: 'Adverse report',
                    icon: 'circle-dollar-to-slot',
                    route: { params: { configName: 'pdm-adverse-event-report' }, name: 'listing' },
                    permissions: ['view adverse event reports', 'pdm-reports'],
                },
            ],
        },
    ],
}

export default pdm
