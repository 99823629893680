import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectAuthStatus, setAuthSession, authTokenExpired, logout } from '@/features/user/userSlice'

const PrivateRoute = ({ children, component: Component, ...props }) => {
    const authStatus = useSelector(selectAuthStatus)
    const authRemember = useSelector((state) => state.user.remember)
    const authExpiresAt = useSelector((state) => state.user.expiresAt)
    const dispatch = useDispatch()

    useEffect(() => {
        if (authStatus) {
            const state = { remember: authRemember, expiresAt: authExpiresAt }
            if (authTokenExpired(state)) {
                dispatch(logout())
            } else {
                dispatch(setAuthSession(state))
            }
        }
    }, [authStatus])

    let view = children
    if (Component) {
        view = <Component />
    }

    return (
        <Route
            {...props}
            render={({ location }) =>
                authStatus ? view : <Redirect to={{ pathname: '/login', state: { from: location } }} />
            }
        />
    )
}

export default PrivateRoute
