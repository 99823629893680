import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { getRouteConfig } from '@/router/routerConfig'
import { interpolateEndpointValues } from '@/helpers/apiHelper'
import Icon from '@/components/Icon'
import styled from 'styled-components'

const Link = ({ to: originalTo, children, icon = null, ...props }) => {
    let to = originalTo

    if (typeof originalTo === 'object') {
        const { name, params } = originalTo
        if (name) {
            const config = getRouteConfig({ name })
            to = `/${interpolateEndpointValues(config.path, params)}`
        }
    }

    return (
        <RouterLink to={to} {...props}>
            {icon ? <Icon icon={icon} btn /> : null}
            {children}
        </RouterLink>
    )
}

const ExternalLinkContentContainer = styled.span`
    display: block;
    padding-right: 15px;
`

const ExternalLinkIconContainer = styled.span`
    margin-left: 5px;
    margin-right: -15px;
    padding-bottom: 1px;
    font-size: 0.8em;
    color: #ee87a5;
`

const TableLink = ({ children, ...props }) => {
    return (
        <Link {...props}>
            <ExternalLinkContentContainer>
                {children}
                <ExternalLinkIconContainer>
                    <Icon icon="external-link-alt" />
                </ExternalLinkIconContainer>
            </ExternalLinkContentContainer>
        </Link>
    )
}

export default Link
export { TableLink }
