import { useState, useEffect } from 'react'
import { message } from 'antd'
import Api, { alerts as alertsApi } from '@/Api'

const alertDescription = (data) => {
    let percent = 'unknown percent'
    if (data && data.percent) {
        percent = `${data.percent}%`
    }
    if (data && data.course_name) {
        return `Course - ${data.course_name} is verified.`
    }
    return `exceeded recommended end-user price by ${percent}`
}
const alertTitle = (data) => {
    const { product = {} } = data.data || {}
    if (data.event === 'your_product_price_exceeded_recommended_price') {
        return product.title || 'Exceeded recommended product price'
    }
    if (data.event === 'product_price_exceeded_recommended_price') {
        return `${data.data.causer.display_name}'s product ${product.title}`
    }
    if (data.event === 'course_verified') {
        return 'Course Verified'
    }
    return ''
}

const mutateAlerts = (alert) => ({
    id: alert.id,
    title: alertTitle(alert),
    description: alertDescription(alert.data),
    seen: alert.read_at !== null,
})

const useAlerts = (socket) => {
    const [alerts, setAlerts] = useState([])

    useEffect(() => {
        let mounted = true

        const fetchAlerts = async () => {
            try {
                const response = await Api.get(alertsApi.endpoint)
                const data = response.data.data.map(mutateAlerts)
                if (mounted) {
                    setAlerts(data)
                }
            } catch {
                // message.error(`Couldn't fetch notifications from server`)
            }
        }

        fetchAlerts()

        return () => {
            mounted = false
        }
    }, [])

    useEffect(() => {
        let mounted = true

        const fetchAlerts = async () => {
            try {
                const response = await Api.get(alertsApi.endpoint)
                const data = response.data.data.map(mutateAlerts)
                if (mounted) {
                    setAlerts(data)
                }
            } catch {
                // message.error(`Couldn't fetch notifications from server`)
            }
        }

        if (socket) {
            fetchAlerts()

            socket.on('notification', (response) => {
                const newAlert = mutateAlerts(response.data)

                if (mounted) {
                    setAlerts((currentAlerts) => [newAlert, ...currentAlerts])
                }
            })
        }

        return () => {
            mounted = false
        }
    }, [socket])

    const readAlert = async (alertId) => {
        if (alerts.findIndex((alert) => alert.id === alertId && alert.seen) === -1) {
            try {
                const response = await Api.request(alertsApi.read, { id: alertId })

                if (!response.data.success) {
                    throw new Error()
                }

                setAlerts((currentAlerts) => {
                    const index = alerts.findIndex((alert) => {
                        return alert.id === alertId
                    })
                    if (index !== -1) {
                        const newAlerts = alerts.slice()
                        newAlerts[index].seen = true
                        return newAlerts
                    }
                    return currentAlerts
                })
            } catch {
                message.error(`Couldn't set alert as seen`)
            }
        }
    }

    return [alerts, readAlert]
}

export default useAlerts
