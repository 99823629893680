import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Api, { user } from '@/Api'
import { selectAuthStatus, logout } from '@/features/user/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from 'antd'

const Logout = () => {
    const authStatus = useSelector(selectAuthStatus)
    const dispatch = useDispatch()

    useEffect(() => {
        Api.request(user.logout).finally(() => {
            dispatch(logout())
        })
    }, [])

    if (!authStatus) {
        return <Redirect to="/login" />
    }
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography.Text>logging out...</Typography.Text>
        </div>
    )
}

export default Logout
