import { setupCache } from 'axios-cache-adapter'

const CACHE_MAX_AGE_MINUTES = 16

const removeStoreItemByUrlSegment = async (config, urlStart) => {
    try {
        const uuids = Object.keys(config.store.store || {}).filter((storeItem) => storeItem.startsWith(urlStart))
        await Promise.all(
            uuids.map(async (uuid) => {
                config.store.removeItem(uuid)
            })
        )
    } catch {
        //
    }
}

const invalidateMediaPutParent = async (baseURL, config, request) => {
    const reg = /(media)\/([0-9]+)\/put(.*)$/

    if (reg.test(request.url)) {
        const urlStart = `${baseURL}${request.url.replace(reg, '$2')}`
        await removeStoreItemByUrlSegment(config, urlStart)
    }
}

const invalidateThreadCache = async (baseURL, config, request) => {
    const reg = /(threads|media)\/(rename|move)\/([0-9]+)$/

    if (reg.test(request.url)) {
        const urlStart = `${baseURL}${request.url.replace(reg, 'threads/')}`
        await removeStoreItemByUrlSegment(config, urlStart)
    }
}

// eslint-disable-next-line import/prefer-default-export
export const getCacheAdapter = (baseURL) => {
    const cache = setupCache({
        maxAge: 1000 * 60 * CACHE_MAX_AGE_MINUTES, // 15 minutes
        exclude: {
            query: false,
            methods: ['put', 'patch', 'delete'],
            paths: [
                /login$/,
                /logout$/,
                /user$/,
                /password\/forgot$/,
                /password\/reset$/,
                /create$/,
                /update\/([0-9]+)$/,
                /delete\/([0-9]+)$/,
                /media\/([0-9]+)\/put$/,
                /media\/([0-9]+)\/delete$/,
                /media\/([0-9]+)\/order/,
                /threads\/json\/([0-9]+)$/,
                /threads\/move\/([0-9]+)$/,
                /threads\/download/,
                /threads\/duplicate\/([0-9]+)$/,
                /threads\/share\/([0-9]+)$/,
                /threads\/stop-sharing\/([0-9]+)$/,
                /threads\/link\/([0-9]+)$/,
                /threads\/archive\/([0-9]+)$/,
                /threads\/toggle-notifications\/([0-9]+)$/,
                /rename\/([0-9]+)$/,
                /verify\/([0-9]+)$/,
                /duplicate\/([0-9]+)$/,
                /set-location\/([0-9]+)$/,
                /attend\/([0-9]+)\/([0-9]+)$/,
                /impersonate/,
                /impersonate\/([0-9]+)\/country$/,
                /user/,
                /update-order\/([0-9]+)$/,
                /hyaluronic-orders-purchase\/([0-9]+)$/,
                /courses\/json\/([0-9]+)$/,
                /courses\/bonus\/request/,
                /customers\/json\/([0-9]+)$/,
                /customers\/remind-diploma\/([0-9]+)$/,
                /customers\/search/,
                /countries\/json/,
                /customers\/json/,
                /countries\/bonuses\/deduct/,
                /countries\/bonuses\/create/,
                /countries\/bonuses\/modify/,
                /courses\/hands-on\/verify\/accepted\/([0-9]+)$/,
                /courses\/hands-on\/verify\/rejected\/([0-9]+)$/,
                /coach\/my-documents\/json/,
                /coach\/position/,
                /coach\/json/,
                /profile\/members\/delete/,
                /roles\/json\/([0-9]+)$/,
                /permissions\/json/,
                /mdf\/goals\/update/,
                /mdf\/goals\/list/,
                /mdf\/sales\/update/,
                /mdf\/sales\/json/,
                /mdf\/bonuses\/json/,
                /mdf\/bonuses\/generate/,
                /mdf\/requests$/,
                /mdf\/requests\/([0-9]+)\/comments/,
                /mdf\/reporting\/spend\/exports/,
                /mdf\/reporting\/balance\/exports/,
                /mdf\/sales\/exports/,
                /mdf\/goals\/exports/,
                /pdm\/patients/,
                /pdm\/patients\/json\/([0-9]+)$/,
                /pdm\/reports\/finance\/json/,
                /pdm\/reports\/finance\/export/,
                /products\/list/,
                /loyalty\/print\/json/,
                /loyalty\/status/,
                /loyalty\/print\/lot/,
                /loyalty\/reports\/export/,
            ],
        },
        invalidate: async (config, request) => {
            await invalidateMediaPutParent(baseURL, config, request)
            await invalidateThreadCache(baseURL, config, request)

            const reg = /(create$|(update|delete|duplicate|toggle-notifications|verify|set-password|read)\/([0-9]+)$)/
            if (reg.test(request.url)) {
                const urlStart = `${baseURL}${request.url.replace(reg, '')}`
                await removeStoreItemByUrlSegment(config, urlStart)
            } else if (request.url === 'login' || request.url.startsWith('impersonate/')) {
                // remove old cache on login and impersonation
                await removeStoreItemByUrlSegment(config, '')
            }
        },
    })

    return cache.adapter
}
