import React from 'react'
import { Row, Col, Card } from 'antd'
import './AuthStyles.scss'
import logoSVG from '@/theme/img/awork_logo.svg'

const AuthLayout = ({ children }) => {
    return (
        <div className="auth-wrapper">
            <div className="auth-container">
                <div className="auth-bg-wrapper">
                    <div className="auth-bg-container">
                        <span>
                            <span />
                        </span>
                    </div>
                </div>
                <Row justify="center">
                    <Col>
                        <div className="auth-form-wrapper">
                            <img src={logoSVG} alt="Awork" className="auth-logo" />
                            <Card>{children}</Card>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="auth-footer">
                <div className="auth-footer-version">v3.0.0</div>
            </div>
        </div>
    )
}

export default AuthLayout
