import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import Icon from '@/components/Icon'
import { getRouteConfig } from '@/router/routerConfig'
import { sidebarConfig } from '@/navigation'

const flatSidebarConfig = sidebarConfig.reduce((prev, curr) => {
    const children = (curr.children || []).map((child) => {
        return { ...child, parentKey: curr.key }
    })
    return [...prev, curr, ...children]
}, [])

const SidebarMenu = () => {
    const [selectedKeys, setSelectedKeys] = useState([])
    const [openKeys, setOpenKeys] = useState([])

    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const sidebarRouteConfig = flatSidebarConfig.find(({ route = false }) => {
            if (!route) return false

            if (typeof route === 'string') {
                return route === location.pathname
            }
            return route.path && route.path === location.pathname
        })

        const { key, parentKey } = sidebarRouteConfig || {}

        if (parentKey) {
            setOpenKeys([parentKey])
        }

        if (key) {
            setSelectedKeys([key])
        } else {
            const routeConfig = getRouteConfig({ path: location.pathname })
            if (routeConfig) {
                setSelectedKeys([routeConfig.name])
            }
        }
    }, [location.pathname])

    const onClick = ({ key }) => {
        const confItem = flatSidebarConfig.find((conf) => conf.key === key)
        const { route } = confItem || {}

        let path
        if (typeof route === 'string') {
            path = route
        } else {
            const routeConfig = getRouteConfig(route)
            path = routeConfig.path
        }

        if (confItem) {
            setSelectedKeys([confItem.key])
            setOpenKeys(confItem.parentKey ? [confItem.parentKey] : [])
        }

        history.push(path)
    }

    const onOpenChange = (keys) => {
        setOpenKeys(keys)
    }

    return (
        <Menu
            mode="inline"
            onClick={onClick}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
        >
            {sidebarConfig.map((item) =>
                item.children ? (
                    <Menu.SubMenu
                        key={item.key}
                        icon={<Icon icon={item.icon} className="ant-menu-item-icon" />}
                        title={item.label}
                    >
                        {item.children.map((childItem) => (
                            <Menu.Item key={childItem.key} icon={<Icon icon={childItem.icon} />}>
                                {childItem.label}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                ) : (
                    <Menu.Item key={item.key} icon={<Icon icon={item.icon} />}>
                        {item.label}
                    </Menu.Item>
                )
            )}
        </Menu>
    )
}

export default SidebarMenu
