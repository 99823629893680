import React, { useState, useEffect } from 'react'
import { Layout as Container, Tooltip, Button, Space, notification } from 'antd'
import Icon from '@/components/Icon'
import styled from 'styled-components'
import UserMenu from '@/components/UserMenu'
import 'flag-icon-css/sass/flag-icon.scss'
import { useDispatch, useSelector } from 'react-redux'
import useSocket from '@/hooks/useSocket'
import AworkLogo from '@/theme/img/awork_logo.svg'
import Link from '@/components/Link'
import { impersonationStop } from '@/features/user/userSlice'
import Api, { impersonate } from '@/Api'
import { useHistory } from 'react-router-dom'
import SidebarMenu from './SidebarMenu'
import NotificationBar from './NotificationBar'

const { Header, Sider, Content } = Container

const ToggleButton = styled.button`
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 17px;
    position: relative;
    transition: opacity 0.3s linear;
    &:hover {
        opacity: 0.85;
    }

    margin-left: ${(props) => (props.isCountry ? '20px' : '0')};
    &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 30%;
        top: 35%;
        left: -10px;
        background: #f0f0f0;
        display: ${(props) => (props.isCountry ? 'block' : 'none')};
    }

    svg {
        transform: translateY(1px);
    }
`

const Logo = styled.img`
    height: 64px;
    transform: translateX(-11px);
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.8;
    }
`

const Layout = (props) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(null)
    const [loading, setLoading] = useState(false)
    const { role, impersonating } = useSelector((state) => state.user)
    const socket = useSocket()
    const isRoleCountry = role === 'country' || props.isGuest
    const isRoleRoot = role === 'root' && !props.isGuest
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const toggleStatus = localStorage.getItem('toggle-notifications') === 'false'
        if (toggleStatus) {
            setSidebarCollapsed(toggleStatus)
        }
    }, [sidebarCollapsed])

    useEffect(() => {
        if (props.isGuest) {
            setSidebarCollapsed(true)
        }
    }, [])

    const stopImpersonation = async () => {
        setLoading(true)
        try {
            const response = await Api.request(impersonate.stop)
            if (!response.data.success) {
                throw new Error()
            }
            dispatch(impersonationStop())
            setLoading(false)
            notification.success({ message: 'Success', description: 'Impersonation stopped' })
            history.push('/')
        } catch {
            notification.success({ message: 'Success', description: 'Failed to stop impersonation' })
            setLoading(false)
        }
    }

    const toggleSidebar = () => {
        setSidebarCollapsed((prevState) => {
            localStorage.setItem('toggle-notifications', prevState || false)
            return !prevState
        })
    }

    let containerClass = 'main-layout-container '
    containerClass += `main-layout-sider-${role === 'root' ? 'left' : 'right'} `
    containerClass += sidebarCollapsed ? `main-layout-sider-${role === 'root' ? 'left' : 'right'}-collapsed` : ''

    return (
        <Container className={containerClass} hasSider>
            {isRoleRoot && (
                <Sider className="ant-layout-sider-fixed" trigger={null} collapsible collapsed={sidebarCollapsed}>
                    <div className="ant-layout-sider-header">
                        <span>sPoint</span>
                        <span>v3.0.0</span>
                    </div>
                    <SidebarMenu />
                </Sider>
            )}
            <Container>
                <Header className="ant-layout-header-fixed">
                    {isRoleCountry ? (
                        <Link to="/">
                            <Logo src={AworkLogo} alt="Awork" />
                        </Link>
                    ) : (
                        <ToggleButton type="button" onClick={toggleSidebar}>
                            <Tooltip
                                title={`${sidebarCollapsed ? 'Maximize' : 'Minimize'} Sidebar Menu`}
                                placement="bottomLeft"
                                mouseEnterDelay={0.5}
                                mouseLeaveDelay={0}
                            >
                                <Icon icon="bars" />
                            </Tooltip>
                        </ToggleButton>
                    )}
                    {impersonating ? (
                        <Space size={16} style={{ marginLeft: 'auto' }}>
                            <span>Impersonation mode</span>
                            <Button
                                danger
                                icon={<Icon icon="user-slash" btn />}
                                onClick={stopImpersonation}
                                disabled={loading}
                            >
                                Stop Impersonation
                            </Button>
                        </Space>
                    ) : null}

                    {!props.isGuest && <UserMenu socket={socket} />}
                    {(isRoleCountry && !props.isGuest) && (
                        <ToggleButton type="button" onClick={toggleSidebar} isCountry>
                            <Tooltip
                                title={`${sidebarCollapsed ? 'Show' : 'Hide'} Notification Bar`}
                                placement="bottomRight"
                                mouseEnterDelay={0.5}
                                mouseLeaveDelay={0}
                            >
                                <Icon icon={sidebarCollapsed ? 'compress' : 'expand'} />
                            </Tooltip>
                        </ToggleButton>
                    )}
                </Header>
                <Content>{props.children}</Content>
            </Container>
            {(isRoleCountry && !props.isGuest) && (
                <Sider
                    className="ant-layout-sider-fixed"
                    trigger={null}
                    collapsible
                    collapsed={sidebarCollapsed}
                    collapsedWidth={0}
                >
                    <div className="ant-layout-sider-header">Notifications</div>
                    <NotificationBar socket={socket} />
                </Sider>
            )}
        </Container>
    )
}

export default Layout
