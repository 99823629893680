import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import SuspenseFallback from '@/components/SuspenseFallback'
import App from './App'
import { store, persistor } from './app/store'

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={<SuspenseFallback />} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById('root')
)
