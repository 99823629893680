import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    tabLanguages: ['en'],
}

const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountryLanguage: (state, { payload }) => {
            state.tabLanguages[1] = payload ? payload.toLowerCase() : payload
        },
    },
})

export const { setCountryLanguage } = countrySlice.actions
export default countrySlice.reducer
