import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import Loading from './Loading'

const LoadingContainer = styled.div`
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const SuspenseFallback = ({ fullScreen = true }) => {
    if (fullScreen) {
        return (
            <div>
                <LoadingContainer>
                    <Loading />
                </LoadingContainer>
            </div>
        )
    }

    return (
        <Row align="middle" justify="center" style={{ height: '100%' }}>
            <Col>
                <Loading />
            </Col>
        </Row>
    )
}

export default SuspenseFallback
