// @flow
import axios from 'axios'
import type { Endpoint, EndpointCollection } from '@/types/apiTypes'
import { mutateEndpointConfig, interpolateEndpointValues, createUrl, multipartHeaders } from '@/helpers/apiHelper'
import { authTokenExpired, logout, setAuthSession } from '@/features/user/userSlice'
import { getCacheAdapter } from '@/helpers/cacheHelper'
import { store } from '@/app/store'

const baseURL = process.env.NODE_ENV === 'development' ? '/api/' : `${process.env.REACT_APP_BACKEND_URL || ''}/api/`

const instance = axios.create({
    baseURL,
    withCredentials: true,
    adapter: getCacheAdapter(baseURL),
})

instance.interceptors.request.use(
    (requestConfig) => {
        const { user: userState } = store.getState()

        if (userState.authStatus) {
            const { remember, expiresAt } = userState
            if (!authTokenExpired({ remember, expiresAt })) {
                store.dispatch(setAuthSession({ remember }))
            } else {
                store.dispatch(logout())
                throw new axios.Cancel('auth token expired')
            }
        }

        return requestConfig
    },
    (err) => {
        return Promise.reject(err)
    }
)

instance.interceptors.response.use(
    (response) => response,
    (err) => {
        const { config = {}, status, data: errData = {} } = err.response || {}
        const error = { ...err, canceled: axios.isCancel(err) }

        if (
            (!['login', 'logout'].includes(config.url || '') && status === 401) ||
            (config.url === 'login' && status === 401 && errData.error === 'Authenticated.')
        ) {
            return new Promise((resolve, reject) => {
                instance.post('logout').finally(() => {
                    sessionStorage.setItem('clear_login_redirect', '1')
                    store.dispatch(logout())
                    reject(error)
                })
            })
        }

        return Promise.reject(error)
    }
)

const Api = {
    request: (config: Endpoint | string, data: any = {}, params: any = {}): Promise<any> => {
        const conf: any = typeof config === 'function' ? config(data) : config
        const newConfig: any = mutateEndpointConfig(conf)
        const url = interpolateEndpointValues(newConfig.url, data)
        const mergedData = { ...newConfig.data, ...data }
        return instance.request({ ...newConfig, url, data: mergedData, ...params })
    },
    get: (endpoint: string, request: any = {}, params: any = {}, method = 'post'): Promise<any> => {
        const defaultUrl = method.toLowerCase() === 'get' ? '' : 'json'
        const url = createUrl(endpoint, request, defaultUrl, true)
        return instance[method.toLowerCase()](url, request, params)
    },
    create: (endpoint: string, request: any = {}, params: any = {}): Promise<any> => {
        const url = createUrl(endpoint, request, 'create')
        return instance.post(url, request, params)
    },
    update: (endpoint: string, request: any = {}, params: any = {}): Promise<any> => {
        const url = createUrl(endpoint, request, 'update', true)
        return instance.post(url, request, params)
    },
    delete: (endpoint: string, request: any = {}, params: any = {}): Promise<any> => {
        const url = createUrl(endpoint, request, 'delete', true)
        return instance.post(url, request, params)
    },
    createMedia: (endpoint: string, request: any = {}, params: any = {}): Promise<any> => {
        const url = createUrl(endpoint, request, 'media', true)
        const mergedParams = { ...multipartHeaders, ...params }
        return instance.post(`${url}/put`, request, mergedParams)
    },
    deleteMedia: (endpoint: string, request: any = {}, params: any = {}): Promise<any> => {
        const url = createUrl(endpoint, request, 'media', true)
        return instance.post(`${url}/delete`, request, params)
    },
    orderMedia: (endpoint: string, request: any = {}, params: any = {}): Promise<any> => {
        const url = createUrl(endpoint, request, 'media', true)
        return instance.post(`${url}/order`, request, params)
    },
    export: (endpoint: string | any, path: string = 'export', customPath: boolean = false) => {
        const anchor = document.createElement('a')
        anchor.setAttribute('href', `${baseURL}${endpoint}${customPath ? '' : `/${path}`}`)
        anchor.setAttribute('target', '_blank')
        anchor.click()
    },
    cancelable(method: string, endpoint: Endpoint, request: any = {}, params: any = {}, reqMethod = 'post'): any {
        const source = axios.CancelToken.source()
        const promise = this[method](endpoint, request, { cancelToken: source.token, ...params }, reqMethod)

        return [promise, source]
    },
    getBaseURL: (): string => instance.defaults.baseURL,
}

export const user: EndpointCollection = {
    login: 'login',
    logout: 'logout',
    data: 'user',
    forgotPassword: 'password/forgot',
    resetPassword: 'password/reset',
}

export const posts: EndpointCollection = {
    endpoint: 'web/posts',
    preview: 'web/posts/preview/:id',
}

export const categories: EndpointCollection = {
    endpoint: 'web/categories',
}

export const archive: EndpointCollection = {
    endpoint: 'archive',
}

export const archiveCategories: EndpointCollection = {
    endpoint: 'archive-categories',
}

export const history: EndpointCollection = {
    endpoint: 'web/history',
}

export const settings: EndpointCollection = {
    endpoint: 'web/settings',
}

export const customers: EndpointCollection = {
    endpoint: 'customers',
    generatePdf: 'customers/generate-pdf/:customerId/:courseId',
    verify: 'customers/verify/:id',
    role: 'customers/role/:id',
    activate: 'customers/activate/:id',
    search: 'customers/search',
    remindDiploma: 'customers/remind-diploma/:id',
    comments: {
        get: 'customers/comments/json',
        create: 'customers/comments/store',
    },
}

export const videos: EndpointCollection = {
    endpoint: 'videos',
}

export const countries: EndpointCollection = {
    endpoint: 'countries',
    setPassword: 'countries/set-password/:id',
    bonuses: {
        endpoint: 'countries/bonuses',
        deduct: 'countries/bonuses/deduct',
    },
}

export const aptosCountries: EndpointCollection = {
    endpoint: 'aptos-countries',
}

export const pins: EndpointCollection = {
    endpoint: 'pins',
}

export const products: EndpointCollection = {
    endpoint: 'products',
    position: 'products/position',
    list: 'products/list',
}

export const productCategories: EndpointCollection = {
    endpoint: 'product-categories',
}

export const productAreas: EndpointCollection = {
    endpoint: 'product-areas',
}

export const productPrices: EndpointCollection = {
    product: 'product-prices/product/:id',
    update: 'product-prices/update/:product_id',
}

export const patientCategories: EndpointCollection = {
    endpoint: { url: 'patient-categories/json/categories' },
    methods: { url: 'patient-categories/json/methods' },
}

export const coach: EndpointCollection = {
    endpoint: 'coach',
    list: 'coach/list',
    stats: 'coach/stats',
    details: 'coach/json/:id',
    setPassword: 'coach/set-password/:coach_id',
    invite: 'coach/invite/:coach_id',
    position: 'coach/position',
    inviteCategories: 'coach/coach-invite-categories',
    calendarEvents: 'coach/calendar-events',
    setLocation: 'coach/set-location/:id',
    legalDocuments: {
        endpoint: 'coach/legal-documents',
        allDocuments: 'coach/legal-documents/json',
        createFolder: 'coach/legal-documents/create-folder',
        create: 'coach/legal-documents/create',
    },
    myDocuments: {
        endpoint: 'coach/my-documents',
        allDocuments: 'coach/my-documents/all-documents',
        sharedFolder: 'coach/my-documents/shared-folder',
        createFolder: 'coach/my-documents/create-folder',
        create: 'coach/my-documents/create',
    },
}

export const coachCategories: EndpointCollection = {
    endpoint: 'coach_category',
}

export const courses: EndpointCollection = {
    endpoint: 'courses',
    duplicate: 'courses/duplicate/:id',
    verify: 'courses/verify/:id',
    stats: 'courses/stats',
    customers: {
        export: 'courses/export/customers/:id',
        markAttended: 'courses/attend/:courseId/:customerId',
    },
    customerStats: {
        endpoint: 'courses/customer-stats',
        get: 'courses/customer-stats/:id',
    },
    recordings: {
        getAll: (request = {}) => ({
            url: 'courses/recordings/tracking/json',
            params: { batch: request.batch },
        }),
        get: 'courses/recordings/tracking/json/:courseId/:customerId',
        batches: 'courses/recordings/tracking/batches',
        export: 'courses/recordings/tracking/export',
    },
    attend: 'courses/attend/:id/:customerId',
    generateBonus: 'courses/generate-bonus/:id/:customerId',
    handsOn: 'courses/hands-on',
    handsOns: {
        accept: 'courses/hands-on/verify/accepted/:id',
        decline: 'courses/hands-on/verify/rejected/:id',
    },
    theories: 'courses/theory',
    bonuses: {
        request: 'courses/bonus/request',
        get: 'courses/bonus/json/:courseId',
    },
}

export const faq: EndpointCollection = {
    endpoint: 'web/faq',
}

export const questions: EndpointCollection = {
    endpoint: 'questions',
}

export const puzzle = {
    codes: 'puzzle/codes',
    customers: 'puzzle/customers',
    gifts: {
        endpoint: 'puzzle/gifts',
        requests: 'puzzle/gifts/requests',
    },
}

export const loyalty = {
    import: 'loyalty/import',
    create: 'loyalty/create',
    status: '/loyalty/status',
    codes: {
        endpoint: '/loyalty/codes',
    },
    print: {
        endpoint: '/loyalty/print',
        export: 'loyalty/print',
        lot: '/loyalty/print/lot',
        moveToPrint: '/loyalty/print/move-to-print',
        done: '/loyalty/print/done/:id',
        close: '/loyalty/print/close/:id',
        duplicate: '/loyalty/print/clone/:id',
    },
    report: {
        endpoint: '/loyalty/reports',
        export: 'loyalty/reports/export',
        products: '/loyalty/reports/products',
        countries: '/loyalty/reports/countries',
        scanned_countries: '/loyalty/reports/scanned-countries',
        doctors: '/loyalty/reports/doctors',
        point_range: '/loyalty/reports/point-range',
        lot_numbers: '/loyalty/reports/lot-numbers',
    }
}

export const rewardsShop: EndpointCollection = {
    endpoint: 'rewards-shop',
    categories: 'rewards-shop/categories',
    position: 'rewards-shop/categories/position',
    products: 'rewards-shop/products',
    slider: 'rewards-shop/sliders',
    sliderPosition: 'rewards-shop/sliders/position',
    orders: 'rewards-shop/orders',
}

export const resources: EndpointCollection = {
    endpoint: 'resources',
}

export const offices: EndpointCollection = {
    endpoint: 'web/offices',
}

export const subscribers: EndpointCollection = {
    endpoint: 'subscribers',
}

export const notifications: EndpointCollection = {
    endpoint: 'notifications',
    read: 'notifications/read/:id',
}

export const alerts: EndpointCollection = {
    endpoint: 'alerts',
    read: 'alerts/read/:id',
}

export const threads: EndpointCollection = {
    endpoint: 'threads',
    duplicate: 'threads/duplicate/:id',
    toggleNotifications: 'threads/toggle-notifications/:id',
    tree: 'threads/tree',
    moveFolder: 'threads/move/:id',
    root: 'threads/root',
    search: 'threads/search/:id',
    download: 'threads/download',
    update: 'threads/update/:id',
    archive: 'threads/archive/:id',
    share: 'threads/share/:id',
    link: 'threads/link/:id',
    stopSharing: 'threads/stop-sharing/:id',
}

export const SharedThreads: EndpointCollection = {
    endpoint: 'shared-threads',
    view: 'shared-threads/media/:id',
}

export const media: EndpointCollection = {
    view: 'media/view/:id',
    move: 'media/move/:id',
    rename: 'media/rename/:id',
}

export const mdf: EndpointCollection = {
    goals: {
        endpoint: 'mdf/goals',
        update: 'mdf/goals/update',
    },
    sales: {
        endpoint: 'mdf/sales',
        update: 'mdf/sales/update',
    },
    bonuses: {
        endpoint: 'mdf/bonuses',
        generate: 'mdf/bonuses/generate',
    },
    materials: 'mdf/materials',
    categories: 'mdf/categories',
    events: 'mdf/events',
    requests: {
        endpoint: 'mdf/requests',
        countries: 'mdf/requests/countries',
        items: 'mdf/requests/items/:id',
        approve: 'mdf/requests/approve/:id',
        comments: {
            create: 'mdf/requests/comment/:id',
            json: 'mdf/requests/:id/comments',
        },
    },
    reporting: 'mdf/reporting',
}

export const pdm: EndpointCollection = {
    patients: {
        endpoint: 'pdm/patients',
        media: 'pdm/patients/media',
    },
    procedures: {
        endpoint: 'pdm/procedures',
    },
    followUps: {
        endpoint: 'pdm/follow-ups',
    },
    reports: {
        financeList: 'pdm/reports/finance',
        financeExport: 'pdm/reports/finance',
        qaList: 'pdm/reports/qa',
        qaExport: 'pdm/reports/qa',
        assessmentReport: 'pdm/reports/assessment',
        assessmentExport: 'pdm/reports/assessment',
        adverseReport: 'pdm/reports/adverse-events',
        adverseExport: 'pdm/reports/adverse-events',
    },
    doctors: {
        endpoint: 'pdm/doctors',
    },
}

export const surveyReports: EndpointCollection = {
    endpoint: 'surveys/reports',
}

export const aptospass: EndpointCollection = {
    roles: { url: 'aptospass/roles', method: 'get' },
}

export const roles: EndpointCollection = {
    endpoint: 'roles',
}

export const permissions: EndpointCollection = {
    endpoint: 'permissions',
}

export const procedures: EndpointCollection = {
    endpoint: 'procedures',
    update: 'procedures/create',
}

export const doctors: EndpointCollection = {
    endpoint: 'doctors',
}

export const countryOrders: EndpointCollection = {
    endpoint: 'country-orders',
    products: 'country-orders/products',
    logisticTypes: 'country-orders/logistic-types',
    updateOrder: 'country-orders/update-order/:id',
    updateItem: 'country-orders/item/update/:id',
    hyaluronicOrders: {
        endpoint: 'country-orders/hyaluronic-orders',
        get: 'country-orders/hyaluronic-orders/:id',
        markAsPaid: 'country-orders/hyaluronic-orders-purchase/:id',
    },
}

export const soldProducts: EndpointCollection = {
    endpoint: 'sold-products',
}

export const salePlans: EndpointCollection = {
    get: { url: 'sale-plans/json' },
    update: { url: 'sale-plans/update' },
}

export const orders: EndpointCollection = {
    endpoint: 'orders',
    stats: {
        customer: 'orders/stats/customer',
        product: 'orders/stats/product',
    },
}

export const news: EndpointCollection = {
    endpoint: 'news',
}

export const profile: EndpointCollection = {
    details: 'profile/details',
    update: 'profile/update',
    media: 'profile/media',
    members: {
        endpoint: 'profile/members',
    },
}

export const currencies: EndpointCollection = {
    endpoint: 'currencies',
}

export const impersonate: EndpointCollection = {
    country: 'impersonate/:id/country',
    coach: 'impersonate/:id/coach',
    stop: 'impersonate/stop',
}

export const intimate: EndpointCollection = {
    products: 'intimate/products',
    areas: 'intimate/areas',
}

export const logs: EndpointCollection = {
    endpoing: 'logs',
    create: 'logs/create',
}

export default Api
