import { useState, useEffect } from 'react'
import { message } from 'antd'
import Api, { notifications as notificationsApi } from '@/Api'
import {
    notificationDate,
    notificationTitle,
    notificationDescription,
    notificationUrl,
} from '@/helpers/notificationHelper'

const mutateNotifications = (notification = {}) => ({
    id: notification.id,
    date: notificationDate(notification.data),
    title: notificationTitle(notification.data),
    description: notificationDescription(notification.data),
    seen: notification.read_at !== null,
    url: notificationUrl(notification.data),
})

const useNotifications = (socket) => {
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        let mounted = true

        const fetchNotifications = async () => {
            try {
                const response = await Api.get(notificationsApi.endpoint)
                const data = response.data.data.map(mutateNotifications)
                if (mounted) {
                    setNotifications(data)
                }
            } catch {
                // message.error(`Couldn't fetch notifications from server`)
            }
        }

        fetchNotifications()

        return () => {
            mounted = false
        }
    }, [])

    useEffect(() => {
        let mounted = true

        if (socket) {
            socket.on('notification', (response) => {
                const newNotification = mutateNotifications(response.data)

                if (mounted) {
                    setNotifications((currentNotifications) => [newNotification, ...currentNotifications])
                }
            })
        }

        return () => {
            mounted = false
        }
    }, [socket])

    const readNotification = async (notificationId) => {
        if (notifications.findIndex((nt) => nt.id === notificationId && nt.seen) === -1) {
            try {
                const response = await Api.request(notificationsApi.read, { id: notificationId })

                if (!response.data.success) {
                    throw new Error()
                }

                setNotifications((currentNotifications) => {
                    const index = notifications.findIndex((notification) => {
                        return notification.id === notificationId
                    })
                    if (index !== -1) {
                        const newNotifications = notifications.slice()
                        newNotifications[index].seen = true
                        return newNotifications
                    }
                    return currentNotifications
                })
            } catch {
                message.error(`Couldn't set notification as seen`)
            }
        }
    }

    return [notifications, readNotification]
}

export default useNotifications
