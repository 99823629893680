const sales = [
    {
        key: 'orders',
        label: 'Orders',
        icon: 'shopping-cart',
        permissions: ['view country orders', 'country-orders'],
        route: { params: { configName: 'orders' }, name: 'tab-listing' },
    },
    {
        key: 'sales-report',
        label: 'Sales Report',
        icon: 'chart-pie',
        permissions: ['view sold products', 'sold-products'], // ['read', 'sold_products'],
        route: { params: { configName: 'sales-report' }, name: 'tab-listing' },
    },
    {
        key: 'ashop',
        label: 'Ashop',
        icon: 'chart-bar',
        permissions: ['view orders', 'orders'],
        route: { params: { configName: 'ashop' }, name: 'listing' },
    },
    {
        key: 'products',
        label: 'Products',
        icon: 'shopping-cart',
        permissions: ['view products', 'products'],
        route: { params: { configName: 'products' }, name: 'listing' },
    },
]

export default sales
