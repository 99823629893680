import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SuspenseFallback from '@/components/SuspenseFallback'
import PrivateRoute from './PrivateRoute'
import { publicRoutes, privateRoutes } from './routerConfig'
import Layout from '@/components/Layout'

const publicRoutePaths = publicRoutes.map((route) => route.path)

const Router = () => (
    <BrowserRouter>
        <Suspense fallback={<SuspenseFallback />}>
            <Switch>
                <Route exact path={publicRoutePaths}>
                    <Switch>
                        {publicRoutes.map(({ name, ...route }) => {
                            if (['shared-file-storage'].includes(name)) {
                                return (
                                    <Layout isGuest>
                                        <Route key={name} {...route} />
                                    </Layout>
                                )
                            }

                            return <Route key={name} {...route} />
                        })}
                    </Switch>
                </Route>
                <PrivateRoute exact path={['/', '/*']}>
                    <Layout>
                        <Suspense fallback={<SuspenseFallback fullScreen={false} />}>
                            <Switch>
                                {privateRoutes.map(({ name, ...route }) => (
                                    <PrivateRoute key={name} {...route} />
                                ))}
                            </Switch>
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            </Switch>
        </Suspense>
    </BrowserRouter>
)

export default Router
