const mdf = {
    key: 'mdf',
    label: 'MDF',
    icon: 'handshake',
    route: { params: { page: 'mdf' }, name: 'dashboard' },
    children: [
        {
            key: 'mdf-sales-plan',
            label: 'Sales Plan',
            icon: 'bullseye',
            route: { name: 'mdf-sales-plan' },
            permissions: ['view annual goals', 'annual-goals'],
        },
        {
            key: 'mdf-actual-sales',
            label: 'Actual Sales',
            icon: 'circle-dollar-to-slot',
            route: { name: 'mdf-actual-sales' },
            permissions: ['view mdf sales', 'mdf-sales'],
            children: [
                {
                    key: 'mdf-bonuses',
                    label: 'Mdf recommended bonuses',
                    icon: 'list-alt',
                    route: { name: 'mdf-bonuses' },
                    hidden: true,
                },
            ],
        },
        {
            key: 'mdf-requests',
            label: 'MDF Requests',
            icon: 'fa-receipt',
            permissions: ['view mdf requests', 'mdf-requests'],
            route: { name: 'listing', params: { configName: 'mdf-requests' } },
        },
        {
            key: 'mdf-material',
            label: 'MDF Material',
            icon: 'list-alt',
            route: { params: { page: 'mdf-material' }, name: 'dashboard' },
            permissions: [
                ['role', 'manager'],
                ['view mdf materials', 'mdf-materials'],
            ],
            children: [
                {
                    key: 'mdf-categories',
                    label: 'Categories',
                    icon: 'fa-layer-group',
                    route: { params: { configName: 'mdf-categories' }, name: 'listing' },
                },
                {
                    key: 'mdf-materials',
                    label: 'Materials',
                    icon: 'fa-boxes-stacked',
                    route: { params: { configName: 'mdf-materials' }, name: 'listing' },
                },
                {
                    key: 'mdf-services',
                    label: 'Services',
                    icon: 'fa-school-circle-exclamation',
                    permissions: [
                        ['role', 'manager'],
                        ['view mdf services', 'mdf-services'],
                    ],
                    route: { params: { configName: 'mdf-services' }, name: 'listing' },
                },
            ],
        },
        {
            key: 'mdf-reports',
            label: 'MDF Reports',
            icon: 'fa-chart-column',
            permissions: ['view', 'mdf-reports'],
            route: { params: { page: 'mdf-reports' }, name: 'dashboard' },
            children: [
                {
                    key: 'mdf-balances',
                    label: 'Current Balance',
                    icon: 'fa-chart-column',
                    route: { params: { configName: 'mdf-balances' }, name: 'listing' },
                    permissions: ['view mdf balances', 'mdf-reporting'],
                },
                {
                    key: 'mdf-earning-spending',
                    label: 'Earnt vs Spent',
                    icon: 'fa-chart-column',
                    route: { name: 'mdf-earning-spending' },
                    permissions: ['view mdf earnt vs spent', 'mdf-reporting'],
                },
            ],
        },
    ],
}

export default mdf
