import { Ability, subject as sbj } from '@casl/ability'

let ability = new Ability()
let roleAbility = new Ability()
const roleActions = ['role', 'role_id', 'manager_country']

const parseBackendPermissions = (permissionsRaw = []) => {
    return permissionsRaw.reduce((acc, curr) => {
        if (curr.actions && Array.isArray(curr.actions)) {
            return [...acc, ...curr.actions.map((action) => ({ subject: curr.subject, action }))]
        }
        return [...acc, curr]
    }, [])
}

export const updatePermissions = (user) => {
    const { rolePermissions } = user
    const permissions = parseBackendPermissions(user.permissions)
    ability = new Ability()
    roleAbility = new Ability()
    ability.update(permissions)
    roleAbility.update(rolePermissions)
}

export default (action, subjectRaw, notInverted = true) => {
    const method = notInverted ? 'can' : 'cannot'
    let abilityInstance = ability
    let subject = subjectRaw

    if (roleActions.includes(action)) {
        abilityInstance = roleAbility
        subject = sbj(action, { [action]: subjectRaw })
    }

    return abilityInstance[method](action, subject)
}
