import React from 'react'
import Icon from '@/components/Icon'
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons'

export const emailIcon = <Icon icon={faEnvelope} style={{ marginRight: 6 }} />
export const passwordIcon = <Icon icon={faKey} style={{ marginRight: 6 }} />

export const mutateErrorData = (data, initialErrors) => {
    const newErrors = { ...initialErrors }
    Object.keys(data.errors || {}).forEach((key) => {
        newErrors[key] = {
            validateStatus: 'error',
            help: data.errors[key].join('\n'),
        }
    })
    return newErrors
}

export const clearErrorOnFocus = (e, setErrors) => {
    setErrors((prevErrors) => {
        return { ...prevErrors, [e.target.name]: {} }
    })
}
