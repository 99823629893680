/* eslint-disable no-unused-vars */
import React from 'react'
import useNotifications from '@/hooks/useNotifications'
import { Typography, Empty } from 'antd'
import Link from '@/components/Link'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;

    a {
        display: inline-block;
    }
`

const EmptyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
    color: #b0b0b0;
`

const Notification = styled(Link)`
    display: inline-block;
    padding: 15px 42px 10px 15px;
    border-left: 4px solid;
    border-color: ${(props) => (props['data-seen'] ? '#eee' : '#86C7EE')};
    background-color: #fff;
    transition: background-color 0.3s, opacity 0.2s;
    transition-delay: 0s, 0.2s;
    border-bottom: 1px solid #f5f5f5;

    &:hover {
        background-color: #f7f7f7;
    }

    h5.ant-typography {
        margin-top: 0.7em;
    }
`

const NotificationBar = ({ socket }) => {
    const [notifications, readNotification] = useNotifications(socket)

    return (
        <Container className="main-layout-sider-notifications">
            {notifications.length ? (
                notifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        data-seen={notification.seen}
                        to={notification.url}
                        onClick={() => readNotification(notification.id)}
                    >
                        <Typography.Text type="secondary">{notification.date}</Typography.Text>
                        <Typography.Title level={5}>{notification.title}</Typography.Title>
                        <Typography.Paragraph ellipsis={{ rows: 2 }}>{notification.description}</Typography.Paragraph>
                    </Notification>
                ))
            ) : (
                <EmptyContainer>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No Notifications"
                        imageStyle={{ height: 30 }}
                    />
                </EmptyContainer>
            )}
        </Container>
    )
}

export default NotificationBar
