import React from 'react'
import { Spin } from 'antd'
import styled, { keyframes } from 'styled-components'
import logoSvg from '../theme/img/logo_spinner.svg'

const circleSpin = keyframes`
    to { transform: translate(-100%, -100%) rotate(1turn) }
`

const logoPulse = keyframes`
    to { transform: scale(1.075) }
`

const Container = styled.div`
    display: inline-block;
    width: 60px;
    height: 60px;
    padding: 5px;
    position: relative;
    pointer-events: none;
`

const Logo = styled.img`
    width: 100%;
    height: 100%;
    animation: ${logoPulse} 1s linear infinite alternate;
`

const SpinningCircle = styled.span`
    display: inline-block;
    position: absolute;
    width: 50%;
    height: 50%;
    border: 2px solid #75bee9;
    left: 50%;
    top: 50%;
    transform: translate(-100%, -100%);
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
    border-right: none;
    border-bottom: none;
    animation: ${circleSpin} 1s linear infinite;
`

const Loading = (props) => {
    const { children, ...spinnerProps } = props

    const indicator = (
        <Container>
            <SpinningCircle />
            <Logo src={logoSvg} alt={props.tip || 'Loading...'} />
        </Container>
    )

    return (
        <Spin indicator={indicator} {...spinnerProps}>
            {children}
        </Spin>
    )
}

export default Loading
