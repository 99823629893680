// @flow
import { generatePath } from 'react-router-dom'
import type { Endpoint } from '@/types/apiTypes'

export const multipartHeaders = { headers: { 'Content-Type': 'multipart/form-data' } }

export const createUrl = (endpoint: string, request: any, method: string, hasId: boolean = false): string => {
    const urlArray = [endpoint, method]
    if (hasId) {
        const reqId = request.constructor === window.FormData ? request.get('id') : request.id
        urlArray.push(reqId)
    }
    return urlArray.filter((it) => it).join('/')
}

const defaultEndpointParams = {
    method: 'post',
    data: {},
}
export const mutateEndpointConfig = (config: Endpoint | string): Endpoint | any => {
    if (typeof config === 'string') {
        return { ...defaultEndpointParams, url: config }
    }
    return {
        ...defaultEndpointParams,
        ...config,
        data: { ...defaultEndpointParams.data, ...(config.data || {}) },
    }
}

// export const interpolateEndpointValues = (url: string, data: any = {}): string => {
//     const regexp = /\/:([^/]+)/g
//     const matches = url.matchAll(regexp)

//     if (!matches) {
//         return url
//     }

//     const params = [...matches].reduce((paramsObject, match) => {
//         const optional = match[1].endsWith('?')
//         const key = optional ? match[1].slice(0, -1) : match[1]
//         const param = data[key] || ''

//         if (!param && !optional) {
//             throw new Error(`missing param \`${key}\` in \`${url}\` api call`)
//         }

//         return { ...paramsObject, [`:${match[1]}`]: param }
//     }, {})

//     const newUrl = url.split('/').map((segment) => {
//         return segment.startsWith(':') ? params[segment] : segment
//     })

//     return newUrl.filter((segment) => segment).join('/')
// }

export const interpolateEndpointValues = (url: string, data: any = {}): string => {
    const result = generatePath(url, data)
    if (result.startsWith('/')) {
        return result.slice(1)
    }
    return result
}
